import { useCallback } from 'react';
import { useAuth } from '@hooks/useAuth';
import { useTypedSelector } from '@hooks';

export default function useUserPicture() {
  const { user } = useAuth();
  const commentsList = useTypedSelector(
    (state) => state.mediaViewer.commentsList
  );

  const setMyPicture = useCallback(() => {
    if (!user) return { name: '', userId: '', email: '' };
    const { id, firstName, lastName, email } = user;
    const alt = `${firstName[0]}${lastName && lastName[0]}`.toLocaleUpperCase();
    return { name: alt, email, userId: id };
  }, [user]);

  const getUserPicture = useCallback(
    (commentId: string) => {
      const comment = commentsList.find((it) => it.id === commentId);
      if (!comment?.user) return { name: '', email: '', userId: '' };
      const { email, firstName, id, lastName } = comment.user;
      const alt = `${firstName[0]}${
        lastName && lastName[0]
      }`.toLocaleUpperCase();
      return { name: alt, email, userId: id };
    },
    [commentsList]
  );

  return { setMyPicture, getUserPicture };
}
