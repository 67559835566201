import { fabric } from 'fabric';

type LineCreateProps = {
  e: fabric.IEvent;
  canvas: fabric.Canvas;
  color: string;
  assetAngle: number;
  overObject: { current: boolean };
  objectToCreate: { current: fabric.Object | undefined };
  strokeWidth: number;
};

type LineMoveProps = {
  e: fabric.IEvent;
  canvas: fabric.Canvas;
  objectToCreate: { current: fabric.Object | undefined };
};

type ReadyLineCreatorProps = {
  canvas: fabric.Canvas;
  obj: fabric.Object;
  canModify: boolean;
  overObject: { current: boolean };
  callback: any;
};

const lineOptions = {
  hasRotatingPoint: false,
  type: 'line',
  strokeWidth: 1,
  originX: 'center',
  originY: 'center',
  perPixelTargetFind: true,
  noScaleCache: false,
  strokeUniform: true
};

export function lineCreate({
  e,
  canvas,
  color,
  overObject,
  assetAngle,
  objectToCreate,
  strokeWidth
}: LineCreateProps) {
  const pointer = canvas.getPointer(e.e);
  const points = [pointer.x, pointer.y, pointer.x, pointer.y];
  objectToCreate.current = new fabric.Line(points, {
    ...lineOptions,
    strokeWidth,
    fill: color,
    stroke: color,
    assetAngle
  } as any);
  objectToCreate.current.setControlsVisibility({
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    mtr: false
  });
  objectToCreate.current.on('mouseup', () => {
    overObject.current = false;
  });
  objectToCreate.current.on('mouseover', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mousedown:before', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mouseout', () => {
    overObject.current = false;
  });

  canvas.add(objectToCreate.current);
}

export function lineMove({ e, canvas, objectToCreate }: LineMoveProps) {
  const pointer = canvas.getPointer(e.e, false);
  (objectToCreate.current as any).set({
    x2: pointer.x,
    y2: pointer.y
  });
  canvas.renderAll();
}

export const readyLineCreator = ({
  canModify,
  canvas,
  obj,
  overObject,
  callback
}: ReadyLineCreatorProps) => {
  const { x1, y1, x2, y2 } = obj as any;
  const line = new fabric.Line([x1, y1, x2, y2], {
    ...obj,
    perPixelTargetFind: true
  }).setControlsVisibility({
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    mtr: false
  });
  if (!canModify) {
    line.selectable = false;
    line.hoverCursor = 'normal';
  }

  line.on('mouseup', () => {
    overObject.current = false;
  });
  line.on('mouseover', () => {
    overObject.current = true;
  });
  line.on('mousedown:before', () => {
    overObject.current = true;
  });
  line.on('mouseout', () => {
    overObject.current = false;
  });

  canvas.add(line);
  if (callback) callback();
};
