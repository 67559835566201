import { fabric } from 'fabric';

type RectCreateProps = {
  e: fabric.IEvent;
  canvas: fabric.Canvas;
  color: string;
  assetAngle: number;
  overObject: { current: boolean };
  objectToCreate: { current: fabric.Object | undefined };
  strokeWidth: number;
};

type RectMoveProps = {
  e: fabric.IEvent;
  canvas: fabric.Canvas;
  objectToCreate: { current: fabric.Object | undefined };
};

type ReadyRectCreatorProps = {
  canvas: fabric.Canvas;
  obj: fabric.Object;
  canModify: boolean;
  overObject: { current: boolean };
  callback: any;
};

let origX = 0;
let origY = 0;

const rectOptions = {
  hasRotatingPoint: true,
  type: 'rect',
  strokeWidth: 1,
  fill: '',
  rx: 1,
  ry: 1,
  perPixelTargetFind: true,
  centeredRotation: true,
  noScaleCache: false,
  strokeUniform: true
};

export function rectCreate({
  e,
  canvas,
  color,
  overObject,
  assetAngle,
  objectToCreate,
  strokeWidth
}: RectCreateProps) {
  const pointer = canvas.getPointer(e.e);

  origX = pointer.x;
  origY = pointer.y;

  objectToCreate.current = new fabric.Rect({
    ...rectOptions,
    strokeWidth,
    left: origX,
    top: origY,
    width: pointer.x - origX,
    height: pointer.y - origY,
    stroke: color,
    assetAngle
  } as any);
  objectToCreate.current.setControlsVisibility({
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    mtr: false
  });

  objectToCreate.current.on('mouseup', () => {
    overObject.current = false;
  });
  objectToCreate.current.on('mouseover', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mousedown:before', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mouseout', () => {
    overObject.current = false;
  });
  canvas.add(objectToCreate.current);
}

export function rectMove({ e, canvas, objectToCreate }: RectMoveProps) {
  const pointer = canvas.getPointer(e.e);

  if (origX > pointer.x) {
    (objectToCreate.current as any).set({ left: Math.abs(pointer.x) });
  }
  if (origY > pointer.y) {
    (objectToCreate.current as any).set({ top: Math.abs(pointer.y) });
  }
  (objectToCreate.current as any).set({ width: Math.abs(origX - pointer.x) });
  (objectToCreate.current as any).set({ height: Math.abs(origY - pointer.y) });
  canvas.renderAll();
}

export const readyRectCreator = ({
  canModify,
  canvas,
  obj,
  overObject,
  callback
}: ReadyRectCreatorProps) => {
  const rect = new fabric.Rect({
    ...obj,
    perPixelTargetFind: true
  }).setControlsVisibility({
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    mtr: false
  });
  if (!canModify) {
    rect.selectable = false;
    rect.hoverCursor = 'normal';
  }

  rect.on('mouseup', () => {
    overObject.current = false;
  });
  rect.on('mouseover', () => {
    overObject.current = true;
  });
  rect.on('mousedown:before', () => {
    overObject.current = true;
  });
  rect.on('mouseout', () => {
    overObject.current = false;
  });
  canvas.add(rect);
  if (callback) callback();
};
