import { AssetVersionItemDto } from '@api/Api';

export function checkCanPlaySource(
  version: AssetVersionItemDto
): version is AssetVersionItemDto & { sourceUrl: string } {
  const { mimeType, metadata, sourceUrl } = version;
  if (metadata.type !== 'video') return false;
  if (!sourceUrl) return false;
  if (metadata.info.status !== 'ready') return false;
  const codecs: string[] = [];
  if (metadata.info.avInfo?.video?.codecRfc6381) {
    codecs.push(metadata.info.avInfo.video.codecRfc6381);
  }
  if (metadata.info.avInfo?.audio?.codecRfc6381) {
    codecs.push(metadata.info.avInfo.audio.codecRfc6381);
  }

  if (!codecs.length) return false;
  const videoEl = document.createElement('video');
  const result = videoEl.canPlayType(`${mimeType}; codecs="${codecs.join()}"`);
  return !!result;
}
