import { fabric } from 'fabric';
import DeleteSvg from '@assets/icons/ShapesDrawingTools/delete.svg';

type ChangeControlPositionProps = {
  obj: any;
  angle: number;
};
const img = new Image();
img.src = DeleteSvg.toString();
img.style.objectFit = 'cover';
export default function changeObjectControlPosition({
  obj,
  angle
}: ChangeControlPositionProps) {
  const options = setControllerPosition(angle);
  obj.controls.deleteControl = new fabric.Control({
    ...options,
    cursorStyle: 'pointer',
    mouseUpHandler: (_, transformData) => deleteObjectHandler(transformData),
    render: (ctx, top, left) => renderIcon(ctx, top, left)
  });
}

function setControllerPosition(angle: number) {
  if (angle === 90)
    return {
      x: -0.5,
      y: -0.5,
      offsetY: -18,
      offsetX: -18
    };

  if (angle === 180)
    return {
      x: -0.5,
      y: 0.5,
      offsetY: 18,
      offsetX: -18
    };

  if (angle === 270)
    return {
      x: 0.5,
      y: 0.5,
      offsetY: 18,
      offsetX: 18
    };

  return {
    x: 0.5,
    y: -0.5,
    offsetY: -18,
    offsetX: 18
  };
}

function deleteObjectHandler(transformData: fabric.Transform) {
  const { target } = transformData;
  const { canvas } = target;
  if (!canvas) return false;
  canvas.discardActiveObject();
  canvas.remove(target);
  canvas.renderAll();
  canvas.fire('object:deleted', { target });
  return true;
}

function renderIcon(ctx: CanvasRenderingContext2D, left: number, top: number) {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(0));
  ctx.drawImage(img, -12, -14, 24, 24);
  ctx.restore();
}
