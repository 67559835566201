export const colorPickerColorScheme: string[] = [
  '#FFFFFF',
  '#F75248',
  '#FDDFA6',
  '#A6DCAB',
  '#6B83FF',
  '#F051A3',
  '#F8B54E',
  '#58C97A',
  '#292B2E',
  '#99727B',
  '#9B7D6B',
  '#71967C'
];
