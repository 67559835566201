type fnProps = {
  e: KeyboardEvent;
  undo: () => void;
  redo: () => void;
  historyLength: number;
  mods: number;
};

function getOS(): 'macos' | 'windows' {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const macos = /(macintosh|macintel|macppc|mac68k|macos)/i;
  const windows = /(win32|win64|windows|wince)/i;

  if (macos.test(userAgent)) return 'macos';
  if (windows.test(userAgent)) return 'windows';

  return 'windows';
}

export function getBrowser(): 'chrome' | 'safari' {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const chrome = /chrome|chromium|crios/i;
  const safari = /safari/i;

  if (chrome.test(userAgent)) return 'chrome';
  if (safari.test(userAgent)) return 'safari';

  return 'chrome';
}
export function withKeysCanvasHistoryCopy() {
  const withCmd = { undo: 'Cmd+Z', redo: 'Cmd+Shift+Z' };
  const withCtrl = { undo: 'Ctrl+Z', redo: 'Ctrl+Shift+Z' };
  const os = getOS();
  const browser = getBrowser();
  if (os === 'macos') {
    if (browser === 'safari') return withCtrl;
    if (browser === 'chrome') return withCmd;
  }
  if (os === 'windows') return withCtrl;
  return withCtrl;
}

export function withKeysCanvasHistoryChange({
  e,
  undo,
  redo,
  historyLength,
  mods
}: fnProps) {
  const props = { e, undo, redo, historyLength, mods };
  const os = getOS();
  if (os === 'macos') macPlatformEvent(props);
  if (os === 'windows') winPlatformEvent(props);
}

function macPlatformEvent({ e, undo, redo, historyLength, mods }: fnProps) {
  const { metaKey, shiftKey, key, ctrlKey } = e;
  const browser = getBrowser();
  let optUndo;
  let optRedo;
  if (browser === 'safari') {
    optUndo = isZKey(key) && ctrlKey && !shiftKey;
    optRedo = isZKey(key) && ctrlKey && shiftKey;
  }
  if (browser === 'chrome') {
    optUndo = isZKey(key) && metaKey && !shiftKey;
    optRedo = isZKey(key) && metaKey && shiftKey;
  }
  if (optUndo && mods < historyLength) undo();
  if (optRedo && mods > 0) redo();
}

function winPlatformEvent({ e, undo, redo, historyLength, mods }: fnProps) {
  const { shiftKey, ctrlKey, key } = e;

  const optUndo = isZKey(key) && ctrlKey && !shiftKey;
  const optRedo = isZKey(key) && ctrlKey && shiftKey;

  if (optUndo && mods < historyLength) undo();
  if (optRedo && mods > 0) redo();
}

function isZKey(key: string) {
  return key.toLowerCase() === 'z';
}
