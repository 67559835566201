import { fabric } from 'fabric';

type TextCreateProps = {
  e: fabric.IEvent;
  canvas: fabric.Canvas;
  color: string;
  overObject: { current: boolean };
  assetAngle: number;
  objectToCreate: { current: fabric.Object | undefined };
};

type ReadyTextCreatorProps = {
  canvas: fabric.Canvas;
  obj: fabric.Object;
  canModify: boolean;
  overObject: { current: boolean };
  objectToCreate: { current: fabric.Object | undefined };
  callback: any;
};

const textProps = {
  fontSize: 14,
  perPixelTargetFind: true,
  centeredRotation: true,
  noScaleCache: false,
  strokeUniform: true
};

let origX = 0;
let origY = 0;

export function textCreate({
  e,
  canvas,
  color,
  overObject,
  objectToCreate,
  assetAngle
}: TextCreateProps) {
  const pointer = canvas.getPointer(e.e);

  origX = pointer.x;
  origY = pointer.y;

  objectToCreate.current = new fabric.IText('Type text', {
    ...textProps,
    left: origX,
    top: origY,
    assetAngle,
    fill: color
  } as any);
  objectToCreate.current.setControlsVisibility({
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    mtr: false
  });

  objectToCreate.current.on('mouseup', () => {
    overObject.current = false;
  });
  objectToCreate.current.on('mouseover', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mousedown:before', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mouseout', () => {
    overObject.current = false;
  });
  canvas.add(objectToCreate.current);
  canvas.setActiveObject(objectToCreate.current);
}

export const readyTextCreator = ({
  canModify,
  canvas,
  obj,
  overObject,
  callback
}: ReadyTextCreatorProps) => {
  const { text } = obj as any;

  const textObject = new fabric.IText(text, {
    ...obj
  }).setControlsVisibility({
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    mtr: false
  });
  if (!canModify) {
    textObject.selectable = false;
    textObject.hoverCursor = 'normal';
  }

  textObject.on('mouseup', () => {
    overObject.current = false;
  });
  textObject.on('mouseover', () => {
    overObject.current = true;
  });
  textObject.on('mousedown:before', () => {
    overObject.current = true;
  });
  textObject.on('mouseout', () => {
    overObject.current = false;
  });

  canvas.add(textObject);
  if (callback) callback();
};
