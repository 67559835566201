import { fabric } from 'fabric';

type ArrowCreateProps = {
  e: fabric.IEvent;
  canvas: fabric.Canvas;
  color: string;
  strokeWidth: number;
  objectToCreate: { current: fabric.Object | undefined };
};

type ArrowMoveProps = {
  e: fabric.IEvent;
  canvas: fabric.Canvas;
  objectToCreate: { current: fabric.Object | undefined };
};

type ArrowCreatedWithMouseProps = {
  canvas: fabric.Canvas;
  overObject: { current: boolean };
  objectToCreate: { current: fabric.Object | undefined };
  assetAngle: number;
};

type ReadyArrowCreatorProps = {
  canvas: fabric.Canvas;
  obj: fabric.Object;
  canModify: boolean;
  overObject: { current: boolean };
  callback: any;
};

const arrowOptions = {
  hasRotatingPoint: false,
  type: 'arrow',
  strokeWidth: 1,
  originX: 'center',
  originY: 'center',
  perPixelTargetFind: true,
  noScaleCache: false,
  strokeUniform: true,
  points: []
};

let line: fabric.Line | undefined;
let triangle: fabric.Object | undefined;

let deltaX = 0;
let deltaY = 0;

export function arrowCreate({
  e,
  canvas,
  color,
  objectToCreate,
  strokeWidth
}: ArrowCreateProps) {
  const pointer = canvas.getPointer(e.e);
  const points = [pointer.x, pointer.y, pointer.x, pointer.y];

  line = new fabric.Line(points, {
    strokeWidth,
    fill: color,
    stroke: color,
    originX: 'center',
    originY: 'center',
    type: 'arrow-line'
  }) as fabric.Line;

  const centerX = ((line.x1 || 0) + (line.x2 || 0)) / 2;
  const centerY = ((line.y1 || 0) + (line.y2 || 0)) / 2;
  deltaX = (line.left || 0) - centerX;
  deltaY = (line.top || 0) - centerY;

  triangle = new fabric.Triangle({
    left: (line.get('x1') || 0) + deltaX,
    top: (line.get('y1') || 0) + deltaY,
    originX: 'center',
    originY: 'center',
    selectable: false,
    angle: -45,
    width: 5,
    height: 5,
    scaleX: strokeWidth,
    scaleY: strokeWidth,
    fill: color,
    type: 'arrow-triangle'
  });
  objectToCreate.current = line;
  canvas.add(line, triangle);
}

export function arrowMove({ e, canvas }: ArrowMoveProps) {
  const pointer = canvas.getPointer(e.e);
  if (!line || !triangle) return;
  const { x1, y1, x2, y2 } = line;
  line.set({
    x2: pointer.x,
    y2: pointer.y
  });
  triangle.set({
    left: pointer.x + deltaX,
    top: pointer.y + deltaY,
    angle: calcArrowAngle({
      x1: x1 || 0,
      y1: y1 || 0,
      x2: x2 || 0,
      y2: y2 || 0
    })
  });
  canvas.renderAll();
}

export function beforeArrowCreatedWithMouse({
  canvas
}: {
  canvas: fabric.Canvas;
}) {
  if (!line || !triangle) return;

  canvas.remove(line, triangle);
}

export function arrowCreatedWithMouse({
  canvas,
  overObject,
  objectToCreate,
  assetAngle
}: ArrowCreatedWithMouseProps) {
  if (!line || !triangle) return;
  objectToCreate.current = new fabric.Group([line, triangle], {
    assetAngle,
    type: 'arrow'
  } as any);
  line = undefined;
  triangle = undefined;
  deltaX = 0;
  deltaY = 0;
  objectToCreate.current.setControlsVisibility({
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    mtr: false
  });
  objectToCreate.current.on('mouseup', () => {
    overObject.current = false;
  });
  objectToCreate.current.on('mouseover', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mousedown:before', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mouseout', () => {
    overObject.current = false;
  });
  canvas.add(objectToCreate.current);
}

export const readyArrowCreator = ({
  canModify,
  canvas,
  obj,
  overObject,
  callback
}: ReadyArrowCreatorProps) => {
  const _obj = obj as any;

  let readyLine: any;
  let readyTriangle: any;
  if (_obj.objects) {
    [readyLine, readyTriangle] = _obj.objects;
  } else {
    [readyLine, readyTriangle] = (obj as any).getObjects();
  }

  if (!readyLine || !readyTriangle) return;
  const { x1, y1, x2, y2 } = readyLine;

  const _line = new fabric.Line([x1, y1, x2, y2], readyLine);

  const _triangle = new fabric.Triangle(readyTriangle);

  const arrow = new fabric.Group([_line, _triangle], obj).setControlsVisibility(
    {
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      mtr: false
    }
  );
  if (!canModify) {
    arrow.selectable = false;
    arrow.hoverCursor = 'normal';
  }

  arrow.on('mouseup', () => {
    overObject.current = false;
  });
  arrow.on('mouseover', () => {
    overObject.current = true;
  });
  arrow.on('mousedown:before', () => {
    overObject.current = true;
  });
  arrow.on('mouseout', () => {
    overObject.current = false;
  });
  canvas.add(arrow);
  if (callback) callback();
};

type CalcArrowAngleProps = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

function calcArrowAngle({ x1, y1, x2, y2 }: CalcArrowAngleProps) {
  let angle = 0;

  const x = x2 - x1;
  const y = y2 - y1;
  if (x === 0) {
    angle = y === 0 ? 0 : y > 0 ? Math.PI / 2 : (Math.PI * 3) / 2;
  } else if (y === 0) {
    angle = x > 0 ? 0 : Math.PI;
  } else {
    angle =
      x < 0
        ? Math.atan(y / x) + Math.PI
        : y < 0
        ? Math.atan(y / x) + 2 * Math.PI
        : Math.atan(y / x);
  }
  return (angle * 180) / Math.PI + 90;
}
