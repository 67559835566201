import { fabric } from 'fabric';

type EllipseCreateProps = {
  e: fabric.IEvent;
  canvas: fabric.Canvas;
  color: string;
  assetAngle: number;
  overObject: { current: boolean };
  objectToCreate: { current: fabric.Object | undefined };
  strokeWidth: number;
};

type EllipseMoveProps = {
  e: fabric.IEvent;
  canvas: fabric.Canvas;
  objectToCreate: { current: fabric.Object | undefined };
};

type ReadyEllipseCreatorProps = {
  canvas: fabric.Canvas;
  obj: fabric.Object;
  canModify: boolean;
  overObject: { current: boolean };
  objectToCreate: { current: fabric.Object | undefined };
  callback: any;
};

let origX = 0;
let origY = 0;

const ellipseOptions = {
  hasRotatingPoint: false,
  angle: 0,
  fill: '',
  strokeWidth: 1,
  type: 'ellipse',
  originX: 'left',
  originY: 'top',
  perPixelTargetFind: true,
  noScaleCache: false,
  strokeUniform: true
};

export function ellipseCreate({
  e,
  canvas,
  color,
  overObject,
  objectToCreate,
  assetAngle,
  strokeWidth
}: EllipseCreateProps) {
  const pointer = canvas.getPointer(e.e);
  origX = pointer.x;
  origY = pointer.y;
  objectToCreate.current = new fabric.Ellipse({
    ...ellipseOptions,
    strokeWidth,
    left: origX,
    top: origY,
    rx: pointer.x - origX,
    ry: pointer.y - origY,
    stroke: color,
    assetAngle
  } as any);
  objectToCreate.current.setControlsVisibility({
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    mtr: false
  });
  objectToCreate.current.on('mouseup', () => {
    overObject.current = false;
  });
  objectToCreate.current.on('mouseover', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mousedown:before', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mouseout', () => {
    overObject.current = false;
  });
  canvas.add(objectToCreate.current);
}

export function ellipseMove({ e, canvas, objectToCreate }: EllipseMoveProps) {
  if (!objectToCreate.current) return;
  const pointer = canvas.getPointer(e.e);
  let rx = Math.abs(origX - pointer.x) / 2;
  let ry = Math.abs(origY - pointer.y) / 2;
  if (rx > (objectToCreate.current as any).strokeWidth) {
    rx -= (objectToCreate.current as any).strokeWidth / 2;
  }
  if (ry > (objectToCreate.current as any).strokeWidth) {
    ry -= (objectToCreate.current as any).strokeWidth / 2;
  }
  (objectToCreate.current as any).set({ rx, ry });

  if (origX > pointer.x) {
    (objectToCreate.current as any).set({ originX: 'right' });
  } else {
    (objectToCreate.current as any).set({ originX: 'left' });
  }
  if (origY > pointer.y) {
    (objectToCreate.current as any).set({ originY: 'bottom' });
  } else {
    (objectToCreate.current as any).set({ originY: 'top' });
  }
  canvas.renderAll();
}

export const readyEllipseCreator = ({
  canModify,
  canvas,
  obj,
  overObject,
  callback
}: ReadyEllipseCreatorProps) => {
  const ellipse = new fabric.Ellipse({
    ...obj,
    perPixelTargetFind: true
  }).setControlsVisibility({
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    mtr: false
  });
  if (!canModify) {
    ellipse.selectable = false;
    ellipse.hoverCursor = 'normal';
  }

  ellipse.on('mouseup', () => {
    overObject.current = false;
  });
  ellipse.on('mouseover', () => {
    overObject.current = true;
  });
  ellipse.on('mousedown:before', () => {
    overObject.current = true;
  });
  ellipse.on('mouseout', () => {
    overObject.current = false;
  });

  canvas.add(ellipse);
  if (callback) callback();
};
