import React, { useEffect, useRef, useState, useMemo, useContext } from 'react';
import { useAssetMVPermissions, useTypedSelector } from '@hooks';
import classNames from 'classnames';
import { ReactComponent as UnsupportedSvg } from '@assets/icons/MediaViewer/unsupported.svg';
import { useDispatch } from 'react-redux';
import {
  AudioVideoMetadataDto,
  DocumentMetadataDto,
  ImageMetadataDto
} from '@api/Api';
import { checkCanPlaySource } from '@helpers/checkCanPlaySource';

import ShapesDrawingTools from '@pages/MediaViewer/AssetViewer/ShapesDrawingTools';
import { useSearchParams } from 'react-router-dom';
import { selectComment, setCanvasMode } from '@redux/actions/mediaViewerAction';
import { OnboardingProcessContext } from '@context/OnboardingProcessProvider';
import CollaborateOnAnAssetModal from '@pages/Start/components/Modals/CollaborateOnAnAssetModal';

const PdfCard = React.lazy(
  () => import('@pages/MediaViewer/AssetViewer/PdfCard')
);
const ImageCard = React.lazy(
  () => import('@pages/MediaViewer/AssetViewer/ImageCard')
);
const VideoCard = React.lazy(
  () => import('@pages/MediaViewer/AssetViewer/VideoCard')
);

function AssetViewer() {
  const dispatch = useDispatch();
  const canLeaveComment = useAssetMVPermissions('comment');
  const selectedVersion = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion
  );
  const asset = useTypedSelector(({ mediaViewer }) => mediaViewer.assets);
  const assetType:
    | 'video'
    | 'audio'
    | 'raster_image'
    | 'camera_raw_image'
    | 'vector_image'
    | 'document'
    | 'presentation'
    | 'spreadsheet'
    | undefined = useMemo(() => {
    return selectedVersion?.metadata.type;
  }, [selectedVersion?.id]);
  const isImage =
    assetType === 'raster_image' ||
    assetType === 'camera_raw_image' ||
    assetType === 'vector_image';
  const isDocument =
    assetType === 'document' ||
    assetType === 'presentation' ||
    assetType === 'spreadsheet';
  const isVideo = assetType === 'video';
  const isAudio = assetType === 'audio';

  const assetRef = useRef<any>(null);
  const { onStartTour } = useContext(OnboardingProcessContext);
  const [isUnsupported, setIsUnsupported] = useState<boolean>(false);
  const [isPreparing, setIsPreparing] = useState<boolean>(false);
  const [isContainerExpand, setIsContainerExpand] = useState<boolean>(false);
  const [isAssetReady, setIsAssetReady] = useState<boolean>(false);
  const [collaborateOnAnAssetModal, setCollaborateOnAnAssetModal] =
    useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  useEffect(() => {
    if (!selectedVersion) return;
    if ((selectedVersion.metadata.type as any) === 'unknown') {
      setIsUnsupported(true);
      return;
    }
    if (isVideo) {
      const metadata = selectedVersion.metadata as AudioVideoMetadataDto;
      const videoProxies = Object.entries(metadata.videoProxies).map(
        (el) => el[1]
      );
      const playableVideoProxies = videoProxies.filter(
        (x) => x.status === 'ready'
      );
      if (checkCanPlaySource(selectedVersion)) {
        playableVideoProxies.push(selectedVersion?.sourceUrl);
      }
      const isAnyPending = videoProxies.some((x) => x.status === 'pending');

      if (!playableVideoProxies.length) {
        if (isAnyPending) {
          setIsPreparing(true);
          return;
        }
        setIsUnsupported(true);
      } else {
        setIsPreparing(false);
        setIsUnsupported(false);
      }
    }
    if (isAudio) {
      const metadata = selectedVersion.metadata as AudioVideoMetadataDto;
      if (metadata.audioProxy.status === 'pending') {
        setIsPreparing(true);
        return;
      }
      if (metadata.audioProxy.status === 'ready') {
        setIsPreparing(false);
        setIsUnsupported(false);
        return;
      }
      setIsUnsupported(true);
    }
    if (isDocument || isImage) {
      const metadata = selectedVersion.metadata as
        | ImageMetadataDto
        | DocumentMetadataDto;
      if (metadata.proxy.status === 'pending') setIsPreparing(true);
      else if (metadata.proxy.status === 'ready') {
        setIsPreparing(false);
        setIsUnsupported(false);
      } else setIsUnsupported(true);
    }
  }, [selectedVersion?.metadata, selectedVersion?.id]);

  useEffect(() => {
    const startTour = searchParamsRef.current.get('startTour') ?? undefined;
    if (!asset?.workspaceId) return;
    if (startTour !== 'create-task-from-comment') return;

    if (isAudio || isUnsupported) {
      setCollaborateOnAnAssetModal(true);
      return;
    }
    if (isPreparing) return;
    if (!isAssetReady) return;

    dispatch(selectComment('new'));
    dispatch(setCanvasMode('edit'));
    onStartTour({ type: startTour as any, theme: 'dark' });
  }, [
    asset?.workspaceId,
    dispatch,
    isAssetReady,
    isAudio,
    isPreparing,
    isUnsupported,
    onStartTour
  ]);

  const handleAssetReady = (item?: any) => {
    if (item) assetRef.current = item;
    setIsAssetReady(true);
  };
  const handleAssetError = () => {
    setIsUnsupported(true);
  };

  if (isUnsupported) {
    return (
      <>
        <div
          className={classNames(
            isUnsupported && 'media_viewer_asset-unsupported',
            'media_viewer_asset'
          )}
        >
          <UnsupportedSvg className="b-icon-unsupported" />
          Preview unsupported
        </div>
        {asset?.workspaceId && (
          <CollaborateOnAnAssetModal
            workspaceId={asset.workspaceId}
            setVisible={setCollaborateOnAnAssetModal}
            visible={collaborateOnAnAssetModal}
            error={true}
          />
        )}
      </>
    );
  }

  return (
    <div
      className={classNames(
        isVideo && 'media_viewer_video',
        isAudio && 'media_viewer_audio',
        isDocument && 'media_viewer_document',
        isContainerExpand && 'media_viewer_asset-expanded',
        'media_viewer_asset'
      )}
    >
      {canLeaveComment && <ShapesDrawingTools player={assetRef} />}
      {(isVideo || isAudio) && selectedVersion && (
        <VideoCard
          asset={selectedVersion}
          onError={handleAssetError}
          onReady={handleAssetReady}
          isAssetReady={isAssetReady}
          isPreparing={isPreparing}
        />
      )}
      {isDocument && selectedVersion && (
        <PdfCard
          asset={selectedVersion}
          onReady={handleAssetReady}
          isExpand={isContainerExpand}
          setIsExpand={setIsContainerExpand}
          isPreparing={isPreparing}
        />
      )}
      {isImage && selectedVersion && (
        <ImageCard
          asset={selectedVersion}
          onReady={handleAssetReady}
          isExpand={isContainerExpand}
          setIsExpand={setIsContainerExpand}
          isPreparing={isPreparing}
        />
      )}
    </div>
  );
}

export default AssetViewer;
