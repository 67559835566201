import classNames from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Modal, Carousel, Button } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as ArrowLeftSvg } from '@assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightSvg } from '@assets/icons/arrow-right.svg';
import TextHighlightOne from '@assets/images/media-viewer/text-highlight-1.jpg';
import TextHighlightTwo from '@assets/images/media-viewer/text-highlight-2.jpg';
import TextHighlightThree from '@assets/images/media-viewer/text-highlight-3.jpg';
import TextHighlightOneFour from '@assets/images/media-viewer/text-highlight-4.jpg';

import './TextHighlightModal.less';

type TextHighlightModalProps = {
  onClose: () => void;
  open: boolean;
};

export default function TextHighlightModal({
  open,
  onClose
}: TextHighlightModalProps) {
  const carouselRef = useRef<CarouselRef>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const items = useMemo(() => {
    return [
      {
        title: 'Select text',
        image: TextHighlightOne,
        text: 'Click to highlight text in the document then copy the text to a comment.'
      },
      {
        title: 'Edit text',
        image: TextHighlightTwo,
        text: 'After you select the text, you can edit the text in the comments section on the right.'
      },
      {
        title: 'Copy edited text',
        image: TextHighlightThree,
        text: 'Once the text is edited and you submit the comment, you will see all edits made to the original text.'
      },
      {
        title: 'Copy comment',
        image: TextHighlightOneFour,
        text: 'Once the comment is posted, you can copy the edited text as needed.'
      }
    ];
  }, []);

  const dots = useMemo(() => new Array(items.length).fill(''), [items]);

  const onCancel = useCallback(() => {
    onClose();
    setActiveSlide(0);
  }, [onClose, setActiveSlide]);

  const goNext = useCallback(() => {
    carouselRef.current?.next();
  }, []);

  const goPrev = useCallback(() => {
    carouselRef.current?.prev();
  }, []);

  const goTo = useCallback((index: number) => {
    carouselRef.current?.goTo(index);
  }, []);

  return (
    <Modal
      open={open}
      footer={null}
      centered={true}
      closeIcon={<CrossSvg />}
      destroyOnClose
      width={688}
      onCancel={onCancel}
    >
      <div className="modal_container text-highlight-modal">
        <h2 className="modal_container__title">Text Markup Feature</h2>
        <Carousel
          dots={false}
          ref={carouselRef}
          afterChange={(index) => setActiveSlide(index)}
        >
          {items.map((item, index) => (
            <div key={index}>
              <div className="text-highlight-modal_image">
                <img src={item.image} alt={item.title} />
              </div>
              <div className="text-highlight-modal_title">
                {index + 1}. {item.title}
              </div>
              <div className="text-highlight-modal_text">{item.text}</div>
            </div>
          ))}
        </Carousel>
        <div className="text-highlight-modal_bottom">
          <div className="text-highlight-modal_controls">
            <Button
              type="text"
              icon={<ArrowLeftSvg />}
              onClick={goPrev}
              disabled={activeSlide === 0}
              size="small"
            />
            <div className="text-highlight-modal_dots">
              {dots.map((_item, index) => (
                <div
                  key={index}
                  className={classNames('text-highlight-modal_dots-item', {
                    active: index === activeSlide
                  })}
                  onClick={() => goTo(index)}
                />
              ))}
            </div>
            <Button
              type="text"
              icon={<ArrowRightSvg />}
              onClick={goNext}
              disabled={activeSlide === items.length - 1}
              size="small"
            />
          </div>
          <Button type="link" size="small" onClick={onCancel}>
            Get started
          </Button>
        </div>
      </div>
    </Modal>
  );
}
