export function fitObjectSize(
  zoomFactor: number,
  assetType:
    | 'video'
    | 'audio'
    | 'raster_image'
    | 'camera_raw_image'
    | 'vector_image'
    | 'document'
    | 'presentation'
    | 'spreadsheet'
    | undefined
) {
  const scaleFactor = assetType === 'video' ? 8 : 3;
  const size = scaleFactor / (zoomFactor * 10);
  return size;
}

export function fitPinSize(zoomFactor: number) {
  const size = 1.5 / (zoomFactor * 10);
  return size;
}
