/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext
} from 'react';
import { Tooltip } from 'antd';
import { ReactComponent as Back } from '@assets/icons/ShapesDrawingTools/back.svg';
import { ReactComponent as Forward } from '@assets/icons/ShapesDrawingTools/forward.svg';
import { ReactComponent as Arrow } from '@assets/icons/ShapesDrawingTools/arrow.svg';

import { ReactComponent as Draw } from '@assets/icons/ShapesDrawingTools/draw.svg';
import { ReactComponent as Close } from '@assets/icons/ShapesDrawingTools/close_arrow.svg';
import { ReactComponent as Open } from '@assets/icons/ShapesDrawingTools/open_arrow.svg';
import { ReactComponent as Dnd } from '@assets/icons/ShapesDrawingTools/dnd.svg';
import { ReactComponent as TextHighlightIcon } from '@assets/icons/ShapesDrawingTools/text_highlight.svg';
// import { PushpinOutlined, FontSizeOutlined } from '@ant-design/icons';
import { PushpinOutlined } from '@ant-design/icons';
import { ReactComponent as Stars } from '@assets/icons/ShapesDrawingTools/drawing-tool.svg';
import { colorPickerColorScheme } from '@helpers/ColorPickerColorScheme';
import { shapesScheme } from '@helpers/ShapesScheme';

import './ShapesDrawingTools.less';
import classNames from 'classnames';
import { useTypedSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import {
  selectComment,
  setCanvasMode,
  setDocumentHighlight,
  setDrawToolsColor,
  setDrawToolsShape,
  setToolsExpanded
} from '@redux/actions/mediaViewerAction';
import { toggleSingInExternalReviewerModal } from '@redux/actions/modalAction';
import { useAuth } from '@hooks/useAuth';
import { useResponsive } from '@hooks/useResponsive';
import { LgWidth, MdWidth } from '@components/Responsive';
import useCanvasHistory from '@pages/MediaViewer/AssetViewer/Canvas/hooks/useCanvasHistory';
import {
  withKeysCanvasHistoryChange,
  withKeysCanvasHistoryCopy
} from '@helpers/withKeysCanvasHistoryChange';
import { OnboardingProcessContext } from '@context/OnboardingProcessProvider';
import TextHighlightModal from '@pages/MediaViewer/AssetViewer/PdfCard/components/TextHighlightModal';

function ShapesDrawingTools({ player }: { player?: any }) {
  const drawingToolsContainerRef = useRef<HTMLDivElement>(null);
  const isMouseDownRef = useRef<boolean>(false);
  isMouseDownRef.current = false;
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { isMdWidth } = useResponsive();

  const [openTextHighlightModal, setOpenTextHighlightModal] = useState(false);
  const onboarding = useContext(OnboardingProcessContext);

  const isToolsExpanded = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isToolsExpanded
  );
  const shapesDrawingTools = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.shapesDrawingTools
  );
  const selectedCommentId = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedCommentId
  );

  const canvasMode = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.canvasMode
  );
  const assetType = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion?.metadata.type
  );

  const { history, mods, redoHistory, undoHistory } = useCanvasHistory({
    assetType
  });
  const isDocument =
    assetType === 'document' ||
    assetType === 'presentation' ||
    assetType === 'spreadsheet';
  const [shape, setShape] = useState<string>('rectangle');
  const [isColorPickerVisible, setIsColorPickerVisible] =
    useState<boolean>(false);
  const [isShapePickerVisible, setIsShapePickerVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (assetType === 'video') return;
    dispatch(setDrawToolsShape('pin'));
  }, [dispatch, assetType]);

  useEffect(() => {
    dispatch(setToolsExpanded(canvasMode === 'edit'));
  }, [dispatch, canvasMode]);

  const withKeysHistoryChange = useCallback(
    (e: KeyboardEvent) => {
      if (!isToolsExpanded) return;
      withKeysCanvasHistoryChange({
        e,
        undo: undoHistory,
        redo: redoHistory,
        historyLength: history.length,
        mods
      });
    },
    [history.length, mods, redoHistory, undoHistory, isToolsExpanded]
  );

  useEffect(() => {
    document.addEventListener('keydown', withKeysHistoryChange);
    return () => {
      document.removeEventListener('keydown', withKeysHistoryChange);
    };
  }, [withKeysHistoryChange]);

  useEffect(() => {
    const callback = (e: any) => {
      if (!isMouseDownRef.current || !drawingToolsContainerRef.current) return;
      const skip = e.pageX === 0 && e.pageY === 0;
      if (!skip) {
        drawingToolsContainerRef.current.style.transform = `translate(${e.pageX}px, ${e.pageY}px)`;
      }
    };
    document.addEventListener('mousemove', callback);
    return () => {
      document.removeEventListener('mousemove', callback);
    };
  }, []);

  const undoRedoTooltips = useMemo(() => {
    return withKeysCanvasHistoryCopy();
  }, []);

  const toggleToolbarOpen = useCallback(
    (open) => {
      if (open) {
        if (!user) {
          dispatch(
            toggleSingInExternalReviewerModal({
              visible: true
            })
          );
          return;
        }

        if (canvasMode === 'view') {
          if (selectedCommentId !== 'reply') {
            dispatch(selectComment('new'));
            dispatch(setDocumentHighlight(null));
          }
          dispatch(setCanvasMode('edit'));
        }

        if (player?.current) player.current.pause();
      }
      dispatch(setToolsExpanded(open));
      if (!drawingToolsContainerRef.current) return;
      if (drawingToolsContainerRef.current.style.position !== 'fixed') return;
      const rect = drawingToolsContainerRef.current.getBoundingClientRect();
      const newY = rect.top - drawingToolsContainerRef.current.offsetTop;
      let newX = rect.left - drawingToolsContainerRef.current.offsetLeft;
      if (open) newX += -367;
      else newX += 367;

      drawingToolsContainerRef.current.style.transform = `translate(${newX}px, ${newY}px)`;
    },
    [canvasMode, dispatch, player, selectedCommentId, user]
  );
  const onMouseDown = useCallback((e) => {
    if (!e.target.classList.contains('dragger')) return;
    if (!drawingToolsContainerRef.current) return;
    isMouseDownRef.current = true;
    const rect = drawingToolsContainerRef.current.getBoundingClientRect();
    drawingToolsContainerRef.current.style.position = 'fixed';
    drawingToolsContainerRef.current.style.top = `${rect.y - e.pageY}px`;
    drawingToolsContainerRef.current.style.right = 'unset';
    drawingToolsContainerRef.current.style.left = `${rect.x - e.pageX}px`;
    drawingToolsContainerRef.current.style.transform = `translate(${e.pageX}px, ${e.pageY}px)`;
    drawingToolsContainerRef.current.style.zIndex = '1000';
  }, []);
  const onMouseUp = useCallback(() => {
    isMouseDownRef.current = false;
  }, []);
  return (
    <div
      ref={drawingToolsContainerRef}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      className={classNames(
        isToolsExpanded && 'shapes_drawing_tools_container_open',
        'shapes_drawing_tools_container'
      )}
    >
      <div className="wrapper">
        {isToolsExpanded ? (
          <>
            <Tooltip
              title={
                <div
                  onClick={undoHistory}
                  className="media-viewer-tooltip-inner"
                >
                  Undo ({undoRedoTooltips.undo})
                </div>
              }
              overlayInnerStyle={{ padding: 0 }}
              placement="bottom"
              overlayClassName="toggle_favorite_overlay"
            >
              <span
                role="img"
                aria-label="font-size"
                className={classNames(
                  'anticon anticon-font-size',
                  mods < history.length && 'arrow_active',
                  'arrow',
                  'shapes_drawing_tools-item'
                )}
              >
                <Back onClick={undoHistory} />
              </span>
            </Tooltip>
            <Tooltip
              title={
                <div
                  onClick={redoHistory}
                  className="media-viewer-tooltip-inner"
                >
                  Redo ({undoRedoTooltips.redo})
                </div>
              }
              overlayInnerStyle={{ padding: 0 }}
              placement="bottom"
              overlayClassName="toggle_favorite_overlay"
            >
              <span
                role="img"
                aria-label="font-size"
                className={classNames(
                  'anticon anticon-font-size',
                  mods > 0 && 'arrow_active',
                  'arrow',
                  'shapes_drawing_tools-item'
                )}
              >
                <Forward onClick={redoHistory} />
              </span>
            </Tooltip>
            <Tooltip
              title={
                <div
                  onClick={() => {
                    setIsColorPickerVisible(true);
                  }}
                  className="media-viewer-tooltip-inner"
                >
                  Switch color
                </div>
              }
              overlayInnerStyle={{ padding: 0 }}
              placement="bottom"
              overlayClassName="toggle_favorite_overlay"
            >
              <Tooltip
                placement={isMdWidth ? 'top' : 'bottom'}
                overlayClassName="color_picker_overlay"
                trigger={['click']}
                open={isColorPickerVisible}
                onOpenChange={(v) => setIsColorPickerVisible(v)}
                title={
                  <div className="color_picker_overlay_container">
                    {colorPickerColorScheme.map((el) => (
                      <div
                        key={el}
                        className="color_picker_overlay_container__item"
                        style={{ background: el }}
                        onClick={() => {
                          setIsColorPickerVisible(false);
                          dispatch(setDrawToolsColor(el));
                          if (shapesDrawingTools.shape === 'pin')
                            dispatch(setDrawToolsShape('brush'));
                        }}
                      />
                    ))}
                  </div>
                }
              >
                <div
                  className="color_picker shapes_drawing_tools-item"
                  style={{ background: shapesDrawingTools?.color }}
                />
              </Tooltip>
            </Tooltip>
            <span className="divider" />
            {assetType !== 'audio' && assetType !== 'video' && (
              <Tooltip
                title={
                  <div
                    onClick={() => {
                      dispatch(setDrawToolsShape('pin'));
                    }}
                    className="media-viewer-tooltip-inner"
                  >
                    Add pin
                  </div>
                }
                overlayInnerStyle={{ padding: 0 }}
                placement="bottom"
                overlayClassName="toggle_favorite_overlay"
              >
                <PushpinOutlined
                  onClick={() => {
                    dispatch(setDrawToolsShape('pin'));
                  }}
                  className={classNames(
                    'shapes_drawing_tools-item',
                    shapesDrawingTools?.shape === 'pin' && 'selected'
                  )}
                />
              </Tooltip>
            )}
            {isDocument && (
              <Tooltip
                title={
                  <div
                    onClick={() => {
                      const showOnboarding =
                        !user?.completedOnboardingSteps.includes(
                          'text-highlight'
                        );
                      if (showOnboarding) setOpenTextHighlightModal(true);
                      dispatch(setDrawToolsShape('documentHighlight'));
                    }}
                    className="media-viewer-tooltip-inner"
                  >
                    Text highlight
                  </div>
                }
                overlayInnerStyle={{ padding: 0 }}
                placement="bottom"
                overlayClassName="toggle_favorite_overlay"
              >
                <span
                  role="img"
                  aria-label="font-size"
                  className={classNames(
                    shapesDrawingTools?.shape === 'documentHighlight' &&
                      'selected',
                    'anticon anticon-font-size shapes_drawing_tools-item'
                  )}
                >
                  <TextHighlightIcon
                    onClick={() => {
                      const showOnboarding =
                        !user?.completedOnboardingSteps.includes(
                          'text-highlight'
                        );
                      if (showOnboarding) setOpenTextHighlightModal(true);
                      dispatch(setDrawToolsShape('documentHighlight'));
                    }}
                  />
                </span>
              </Tooltip>
            )}
            {/* <Tooltip
              title={
                <div
                  onClick={() => {
                    dispatch(setDrawToolsShape('text'));
                  }}
                  className="media-viewer-tooltip-inner"
                >
                  Text
                </div>
              }
              overlayInnerStyle={{ padding: 0 }}
              placement="bottom"
              overlayClassName="toggle_favorite_overlay"
            >
              <FontSizeOutlined
                className={classNames(
                  shapesDrawingTools?.shape === 'text' && 'selected',
                  'shapes_drawing_tools-item'
                )}
                onClick={() => {
                  dispatch(setDrawToolsShape('text'));
                }}
              />
            </Tooltip> */}
            <Tooltip
              title={
                <div
                  onClick={() => {
                    dispatch(setDrawToolsShape('arrow'));
                  }}
                  className="media-viewer-tooltip-inner"
                >
                  Arrow
                </div>
              }
              overlayInnerStyle={{ padding: 0 }}
              placement="bottom"
              overlayClassName="toggle_favorite_overlay"
            >
              <span
                role="img"
                aria-label="font-size"
                className={classNames(
                  shapesDrawingTools?.shape === 'arrow' && 'selected',
                  'anticon anticon-font-size shapes_drawing_tools-item'
                )}
              >
                <Arrow
                  onClick={() => {
                    dispatch(setDrawToolsShape('arrow'));
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip
              title={
                <div
                  onClick={() => {
                    setIsShapePickerVisible(true);
                    dispatch(setDrawToolsShape(shape));
                  }}
                  className="media-viewer-tooltip-inner"
                >
                  Shape tools
                </div>
              }
              overlayInnerStyle={{ padding: 0 }}
              placement="bottom"
              overlayClassName="toggle_favorite_overlay"
            >
              <div
                onClick={() => dispatch(setDrawToolsShape(shape))}
                className={classNames(
                  'shape_picker',
                  'shapes_drawing_tools-item',
                  shapesDrawingTools?.shape === shape && 'selected'
                )}
              >
                <div className={classNames(`shape_picker__${shape}`)} />
                <Tooltip
                  placement={isMdWidth ? 'top' : 'bottomRight'}
                  overlayClassName="shape_picker_overlay"
                  trigger={['click']}
                  open={isShapePickerVisible}
                  onOpenChange={(v) => setIsShapePickerVisible(v)}
                  title={
                    <div className="shape_picker_overlay_container">
                      {shapesScheme.map((el) => (
                        <div
                          key={el}
                          className={`shape_picker_overlay_container__item ${
                            shape === el &&
                            'shape_picker_overlay_container__selected_item'
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsShapePickerVisible(false);
                            dispatch(setDrawToolsShape(el));
                            setShape(el);
                          }}
                        >
                          <div className={`shape shape_${el}`} />
                          <div className="name">{el}</div>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <span
                    onClick={() => setIsShapePickerVisible(true)}
                    className="dropdown_arrow"
                  />
                </Tooltip>
              </div>
            </Tooltip>
            <Tooltip
              title={
                <div
                  onClick={() => {
                    dispatch(setDrawToolsShape('brush'));
                  }}
                  className="media-viewer-tooltip-inner"
                >
                  Draw
                </div>
              }
              overlayInnerStyle={{ padding: 0 }}
              placement="bottom"
              overlayClassName="toggle_favorite_overlay"
            >
              <span
                role="img"
                aria-label="font-size"
                className={classNames(
                  'anticon anticon-font-size',
                  'shape_picker',
                  'shapes_drawing_tools-item',
                  shapesDrawingTools?.shape === 'brush' && 'selected'
                )}
              >
                <Draw
                  onClick={() => {
                    dispatch(setDrawToolsShape('brush'));
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip
              title={
                <div
                  onClick={() => toggleToolbarOpen(false)}
                  className="media-viewer-tooltip-inner"
                >
                  Close toolbar
                </div>
              }
              overlayInnerStyle={{ padding: 0 }}
              placement="bottom"
              overlayClassName="toggle_favorite_overlay"
            >
              <span
                className="toggle_open shapes_drawing_tools-item"
                onClick={() => toggleToolbarOpen(false)}
              >
                <Close />
              </span>
            </Tooltip>
          </>
        ) : (
          <>
            <LgWidth>
              <Tooltip
                title={
                  <div
                    onClick={() => toggleToolbarOpen(true)}
                    className="media-viewer-tooltip-inner"
                  >
                    Open toolbar
                  </div>
                }
                overlayInnerStyle={{ padding: 0 }}
                placement="bottom"
                overlayClassName="toggle_favorite_overlay"
              >
                <span
                  className="toggle_open"
                  onClick={() => toggleToolbarOpen(true)}
                >
                  <Open />
                  <Stars className="stars" />
                </span>
              </Tooltip>
            </LgWidth>

            <MdWidth>
              <span
                onClick={() => toggleToolbarOpen(true)}
                className="toggle_open_text"
              >
                Drawing tools
                <span className="toggle_open">
                  <Open />
                </span>
              </span>
            </MdWidth>
          </>
        )}
        <LgWidth>
          <Tooltip
            title="Move toolbar"
            placement="bottom"
            overlayClassName="toggle_favorite_overlay"
          >
            <div className="dragger">
              <Dnd />
            </div>
          </Tooltip>
        </LgWidth>
      </div>
      {isDocument && (
        <TextHighlightModal
          onClose={() => {
            onboarding.onCompleteStep({
              type: 'text-highlight',
              hideCongrats: true
            });
            setOpenTextHighlightModal(false);
          }}
          open={openTextHighlightModal}
        />
      )}
    </div>
  );
}

ShapesDrawingTools.defaultProps = {
  player: null
};

export default ShapesDrawingTools;
