import { fabric } from 'fabric';

type BrushCreateProps = {
  e: fabric.IEvent;
  canvas: fabric.Canvas;
  color: string;
  assetAngle: number;
  overObject: { current: boolean };
  objectToCreate: { current: fabric.Object | undefined };
  strokeWidth: number;
};

type BrushMoveProps = {
  e: fabric.IEvent;
  canvas: fabric.Canvas;
  objectToCreate: { current: fabric.Object | undefined };
};

type ReadyBrushCreatorProps = {
  canvas: fabric.Canvas;
  obj: fabric.Object;
  canModify: boolean;
  assetAngle: number;
  overObject: { current: boolean };
  callback: any;
};

let origX = 0;
let origY = 0;

export const brushOptions = {
  hasRotatingPoint: false,
  type: 'brush',
  strokeWidth: 1,
  fill: '',
  perPixelTargetFind: true,
  noScaleCache: false,
  strokeUniform: true
};

export function brushCreate({
  e,
  canvas,
  color,
  overObject,
  objectToCreate,
  assetAngle,
  strokeWidth
}: BrushCreateProps) {
  const pointer = canvas.getPointer(e.e);
  origX = pointer.x;
  origY = pointer.y;
  objectToCreate.current = new fabric.Polyline([{ x: origX, y: origY }], {
    ...brushOptions,
    stroke: color,
    left: origX,
    top: origY,
    assetAngle,
    strokeWidth,
    id: new Date()
  } as any);
  objectToCreate.current.setControlsVisibility({
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    mtr: false
  });

  objectToCreate.current.on('mouseup', () => {
    overObject.current = false;
  });
  objectToCreate.current.on('mouseover', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mousedown:before', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mouseout', () => {
    overObject.current = false;
  });

  canvas.add(objectToCreate.current);
}

export function brushMove({ e, canvas, objectToCreate }: BrushMoveProps) {
  if (!objectToCreate.current) return;
  const pointer = canvas.getPointer(e.e);
  (objectToCreate.current as any).points.push(
    new fabric.Point(pointer.x, pointer.y)
  );
  const dims = {
    left: objectToCreate.current.left,
    top: objectToCreate.current.top,
    width: objectToCreate.current.width,
    height: objectToCreate.current.height
  };
  const newDims = (objectToCreate.current as any)._calcDimensions();

  objectToCreate.current.left = newDims.left;
  objectToCreate.current.top = newDims.top;
  objectToCreate.current.width = newDims.width;
  objectToCreate.current.height = newDims.height;

  const deltaWidth = (dims.width || 0) - newDims.width;
  const deltaHeight = (dims.height || 0) - newDims.height;
  const deltaLeft = (dims.left || 0) - newDims.left;
  const deltaTop = (dims.top || 0) - newDims.top;
  (objectToCreate.current as any).pathOffset.x -= deltaWidth / 2 + deltaLeft;
  (objectToCreate.current as any).pathOffset.y -= deltaHeight / 2 + deltaTop;
  objectToCreate.current.setCoords();
  objectToCreate.current.dirty = true;

  canvas.renderAll();
}

export const readyBrushCreator = ({
  canModify,
  canvas,
  obj,
  overObject,
  callback
}: ReadyBrushCreatorProps) => {
  const { points } = obj as any;

  const brush = new fabric.Polyline(points, {
    ...obj,
    perPixelTargetFind: true
  } as any).setControlsVisibility({
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    mtr: false
  });
  if (!canModify) {
    brush.selectable = false;
    brush.hoverCursor = 'normal';
  }

  brush.on('mouseup', () => {
    overObject.current = false;
  });
  brush.on('mouseover', () => {
    overObject.current = true;
  });
  brush.on('mousedown:before', () => {
    overObject.current = true;
  });
  brush.on('mouseout', () => {
    overObject.current = false;
  });
  canvas.add(brush);
  if (callback) callback();
};
